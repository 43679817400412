import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      yk_code: '',
      yk_pin: '',
      log_in: 'false',
      yk_detail: [],
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
