<template>
  <div class="install_banner" v-if="deferredPrompt">
    <div class="container">
      <div class="install_banner_content">
        <p>Get our free app. It won't take up space on your device!</p>

        <div class="install_banner_content_button">
          <button @click="dismiss" class="install_banner_content_button-dismiss">Dismiss</button>
          <button @click="install" class="install_banner_content_button-install">Install</button>
        </div>
      </div>
    </div>
  </div>
  <Header_info></Header_info>
  <router-view></router-view>
</template>

<script>
import Header_info from "@/include/HeaderInfo.vue";
export default {
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {
    this.$store.state.log_in = localStorage.getItem('yksm_login');
    if (this.$store.state.log_in != 'true') {
      this.$router.push('/')
    } else {
      this.$router.push('/details')
    }

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  },
  components: {
    Header_info,
  },
}
</script>


<style lang='scss'>
@import "./scss/style.scss";
</style>
