<template>
  <div class="header">
    <div class="container">
      <div class="ykmw_head">
        <img src="../assets/img/ykmw_logo.png" alt="YKMW Logo"/>
        <a v-if="this.$store.state.log_in == 'true'" href="#" @click="logout()">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
  data() {
    return {}
  },
  mounted() {
    this.$store.state.log_in = localStorage.getItem('yksm_login')
  },
  methods: {
    async logout() {
      localStorage.setItem('yksm_login', 'false');
      localStorage.setItem('project_code', '');
      localStorage.setItem('project_pin', '');
      const $toast = useToast();
      $toast.open({
        message: 'Log out success',
        type: 'success',
      });

    }
  }
};
</script>