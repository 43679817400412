import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Login - Yung Kong Metal Works",
      metaTags: [
        { name: "description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { itemprop: "url", content: "https://project.ykmwgroup.my/" },
        { name: "twitter:url", content: "https://project.ykmwgroup.my/" },
        { name: "twitter:description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { itemprop: "name", content: "Login - Yung Kong Metal Works" },
        { property: "og:title", content: "Login - Yung Kong Metal Works" },
        { property: "og:description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { property: "og:url", content: "https://project.ykmwgroup.my/" },
      ],
    },
  },
  {
    path: "/details",
    name: "Delivery Details",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "Delivery Details - Yung Kong Metal Works",
      metaTags: [
        { name: "description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { itemprop: "url", content: "https://project.ykmwgroup.my/details" },
        { name: "twitter:url", content: "https://project.ykmwgroup.my/details" },
        { name: "twitter:description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { itemprop: "name", content: "Delivery Details - Yung Kong Metal Works" },
        { property: "og:title", content: "Delivery Details - Yung Kong Metal Works" },
        { property: "og:description", content: "Yung Kong Metal Works - producing and manufacturing steel welded fabric, Hard Drawn Wire, Ribbed Steel Bar & Coil, and many more..." },
        { property: "og:url", content: "https://project.ykmwgroup.my/details" },
      ],
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
